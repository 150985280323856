<template>
	<div>
        <h2>{{ $t("gynecologie.stat_fertilite") }}</h2>
		<div class="box">
			<CustomTable
	            id_table="horse_stat_fertilite"
	            :items="statFertilite"
	            :busy.sync="table_busy"
	            primaryKey="season_id"
	            :hide_if_empty="true"
	        />
		</div>

		<div class="box">
			<h2>{{ $t('monte.stats_contrats') }}</h2>

			<div v-if="stats_loaded">
				<div v-for="(season, key) in seasons" :key="key">
					<div>
						{{season.season_label}}
						<p><label>{{ $t('monte.contrats_proposes_pas_valide') }}</label> : {{ season.stats.brouillon }}</p>
						<p><label>{{ $t('monte.contrats_valides_signes') }}</label> : {{ season.stats.valides_signes }}</p>
						<p><label>{{ $t('monte.jument_without_contract_but_liaison') }}</label> : {{ season.stats.without_contrats }}</p>
					</div>
				</div>
			</div>
			<div v-else>
				<LoadingSpinner />
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
    import ActesMixin from "@/mixins/Actes.js"
    import GynecologieMixin from "@/mixins/Gynecologie.js"
	import SeasonTransformer from '@/assets/js/dexie/transformers/SeasonTransformer.js'
	import ContractMixin from "@/mixins/Contract.js"

	export default {
		name: 'HorseStatFertilite',
		mixins: [ActesMixin, GynecologieMixin, ContractMixin],
		props: {
			horse_id: { type: [Number, String], default: null }, 
		},
		data () {
			return {
				statFertilite: [],
				saisons: [],
				stats: [],
				stats_loaded: false,
				table_busy: true
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.statFertilite = await this.loadHorseFertilite(this.horse_id)
				this.table_busy = false

				this.seasons = await this.$storage.db.t('season')
				.then(table => {
				    return table.toCollection()
				})
				.then(col => {
				    return col.transform(new SeasonTransformer())
				})
				this.seasons = this.seasons.sort((a,b) => {
					if(a.season_start > b.season_start) {
						return -1
					}
					else {
						return 1
					}
				})
				for (let i = 0; i < this.seasons.length; i++) {
					this.seasons[i].stats = []
					this.seasons[i].stats = await this.getStatsContract(this.horse_id, this.seasons[i].season_id)
				}

				this.stats_loaded = true
			},
		},

		components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>